import React, { Fragment, useCallback, useEffect } from 'react'
import type { FormSectionTransport } from '../../../../common/transports/ho-registration-form-transports'
import {
    Container,
    FormTitle,
    FormSubtitle,
    RegistrationFormContainer,
    AdditionalTextContainer,
    RegistrationFormContentContainer,
    OuterContainer,
    InnerContainer,
    ImageContainer,
    LazyImage,
    SubtitleContent,
} from './ho-registration-form-styles'
import RegistrationForm from './registration-form'
import get from 'lodash/get'
import RegistrationFormModel from './models/registration-form-model'
import { userClient } from '@common/clients'
import SessionStorageManagerService from '@common/services/session-storage-manager-service'
import { AnalyticsService } from '@src/services'
import { registrationFormEventCategories } from '@src/services/analytics-service/categories'
import { registrationFormEventActions } from '@src/services/analytics-service/actions'
import getRegistrationFormFields from '@src/services/analytics-service/utils/get-registration-form-fields'
import { registrationFormEventLabels } from '@src/services/analytics-service/labels'
import { NotificationMessageState } from '@src/redux/notification-channel'
import HouseImage from '../../../assets/picture/houses.png'
import { useTranslation } from '@src/hooks/locale'
import MultiDeviceImageTransport from '@common/transports/common/multi-device-image-transport'

interface Props {
    formSection: FormSectionTransport
    disposeNotification: (message: NotificationMessageState) => void
    backgroundImage: MultiDeviceImageTransport
}

const _trackSubmissionError = (status: number | undefined) => {
    switch (status) {
        case 429: {
            AnalyticsService.trackEvent(
                registrationFormEventActions.ON_SUBMIT,
                registrationFormEventCategories.FORM_ERROR,
                registrationFormEventLabels.formError.RATE_LIMIT_EXCEEDED,
            )

            break
        }
        case 503: {
            AnalyticsService.trackEvent(
                registrationFormEventActions.ON_SUBMIT,
                registrationFormEventCategories.FORM_ERROR,
                registrationFormEventLabels.formError.TEMPORARY_AUTHENTICATION_ERROR,
            )

            break
        }
        case 500: {
            AnalyticsService.trackEvent(
                registrationFormEventActions.ON_SUBMIT,
                registrationFormEventCategories.FORM_ERROR,
                registrationFormEventLabels.formError.INTERNAL_SERVER_ERROR,
            )

            break
        }
        default: {
            AnalyticsService.trackEvent(
                registrationFormEventActions.ON_SUBMIT,
                registrationFormEventCategories.FORM_ERROR,
                registrationFormEventLabels.formError.SOME_SERVER_ERROR,
            )
        }
    }
}

const RegistrationFormSection: React.FC<Props> = ({ formSection, disposeNotification, backgroundImage }) => {
    const { form } = formSection
    const formData = new RegistrationFormModel({
        salutation: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: null,
    })

    useEffect(() => {
        AnalyticsService.trackEvent(
            registrationFormEventActions.ON_DATA_VIEW,
            registrationFormEventCategories.REGISTRATION_FORM,
            getRegistrationFormFields().join(','),
        )
    }, [])

    const { t } = useTranslation()

    const _handleSubmit = useCallback(async (formValues: RegistrationFormModel) => {
        // remoive this comment
        try {
            const registrationRequestBody = RegistrationFormModel.toRequestBody(formValues)
            const registrationResponse = await userClient.register({ registrationRequestBody })
            const verified = registrationResponse.doiState
            SessionStorageManagerService.set('user_verification_data', {
                verified,
                salutation: formValues.salutation,
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                email: formValues.email,
                personId: registrationResponse.personId,
            })
            if (registrationResponse.personId) {
                const customerId = registrationResponse.personId
                AnalyticsService.trackEvent(
                    registrationFormEventActions.ON_SUBMIT,
                    registrationFormEventCategories.NEW_REG,
                    registrationFormEventLabels.clickCompleteRegistration,
                    customerId,
                )
            }
            window.location.href = get(form, 'submitButton.url')
        } catch (error: any) {
            _trackSubmissionError(error?.response?.status || error?.status)
            disposeNotification({ message: error?.message || error, messageType: 'error' })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <OuterContainer src={backgroundImage.desktop.url}>
                <InnerContainer>
                    <Container>
                        <RegistrationFormContentContainer>
                            <FormTitle>{get(formSection, 'title', '')}</FormTitle>
                            <FormSubtitle>
                                <ImageContainer>
                                    <LazyImage alt={t('houseImageAlt')} src={HouseImage} />
                                </ImageContainer>
                                <SubtitleContent>{t('formSubtitle')}</SubtitleContent>
                            </FormSubtitle>
                            <RegistrationFormContainer>
                                <RegistrationForm formData={formData} form={form} onSubmit={_handleSubmit} />
                            </RegistrationFormContainer>
                            <AdditionalTextContainer
                                inputText={get(formSection, 'form.termsAndConditions', '')}
                                escapeHtml
                            />
                        </RegistrationFormContentContainer>
                    </Container>
                </InnerContainer>
            </OuterContainer>
        </Fragment>
    )
}

export default RegistrationFormSection
