import React, { FC, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Device, ScreenContext } from 'react-components'
import { SnackbarProvider } from 'notistack'
import BaseTransport from '@common/transports/common/base-transport'
import { getCheckDeviceSessionTransport, getIsUserLoggedIn } from '@src/redux/user'
import { CommonStyles } from '@src/components/home-page-v2/styles'
import { PageProps } from '@src/types'
import { Language, RequestUserConfig } from '@common/types'
import { GetServerSideProps } from '@src/redux/types'
import { pageClient } from '@common/clients'
import { useTrackUserEntrance } from '@src/hooks/tracking'
import { EventBeaconEvent } from '@common/transports/event-beacon-transport'
import HomePageTransport from '@common/transports/home-page-transport'
import HomePageV2 from '@src/components/home-page-v3'
import Page from '@src/components/common/page'
import { getMessagesFromLanguage } from '@src/i18n/utils'

type Props = {
    baseHomePageTransport: BaseTransport<HomePageTransport>
} & PageProps

const HomePageContainer: FC<Props> = ({ baseHomePageTransport }) => {
    const isLoggedIn = useSelector(getIsUserLoggedIn)
    const dispatch = useDispatch()
    const { isDesktop } = useContext(ScreenContext)

    // On load, check if device session is valid.
    useEffect(() => {
        dispatch(getCheckDeviceSessionTransport())
    }, [dispatch])

    useTrackUserEntrance(EventBeaconEvent.LANDING_PAGE_INDEX)

    const { data: homePageTransport } = baseHomePageTransport

    const {
        head,
        navigation,
        headerSection,
        heroSection,
        trustbarSection,
        benefitsSection,
        howItWorksSection,
        testimonialsSection,
        faqSection,
        footerBannerSection,
        footerSection,
        customerSupportSection,
        moneyBackGuaranteeSection,
        fairnessPrincipleSection,
    } = homePageTransport

    return (
        <Page head={head} logoUrl={headerSection.logo.image?.url}>
            <CommonStyles />
            <SnackbarProvider maxSnack={isDesktop ? 3 : 1} autoHideDuration={1500}>
                <HomePageV2
                    navigation={navigation}
                    headerSection={headerSection}
                    heroSection={heroSection}
                    trustbarSection={trustbarSection}
                    benefitsSection={benefitsSection}
                    howItWorksSection={howItWorksSection}
                    testimonialsSection={testimonialsSection}
                    faqSection={faqSection}
                    footerBannerSection={footerBannerSection}
                    footerSection={footerSection}
                    customerSupportSection={customerSupportSection}
                    moneyBackGuaranteeSection={moneyBackGuaranteeSection}
                    fairnessPrincipleSection={fairnessPrincipleSection}
                    isLoggedIn={isLoggedIn}
                />
            </SnackbarProvider>
        </Page>
    )
}

export const getServerSideProps: GetServerSideProps<Props> = async ({ res }) => {
    const { user, device }: { user: RequestUserConfig; device: Device } = res.locals

    const data = await pageClient.getHomePage({
        headers: { 'x-language': user.language, 'x-domain': user.domain, 'x-version': 'v2' },
    })

    return {
        props: {
            baseHomePageTransport: data,
            device,
            requestUserConfig: user,
            messages: await getMessagesFromLanguage(user.language as Language),
        },
    }
}

export default HomePageContainer
