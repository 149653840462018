import React, { FC, Fragment, memo, useCallback, useContext, useState } from 'react'
import { GhostButton, Header, SolidButton, ScreenContext } from 'react-components'
import { ThemeContext } from 'styled-components'

import NavigationButton from '@src/components/common/navigation-icon'
import Sidebar from '@src/components/sidebar'
import SecondaryNavigationButtons from '@src/components/home-page-v3/header-section/secondary-navigation-buttons'
import CloseIcon from '../../../assets/images/cross-icon.svg'
import {
    BackButton,
    BackIconContainer,
    ButtonContentContainer,
    Container,
    ContentContainer,
    GhostButtonContainer,
    GhostButtonContainerV2,
    HeaderButtonContentContainer,
    IconContainer,
    Image,
    ImageContainer,
    LeftContainer,
    Line,
    LowerContentContainer,
    NavigationContainer,
    NavigationIconContainer,
    PrimaryNavigationContainer,
    RightContainer,
    SecondaryNavigationButtonContainer,
    SecondaryNavigationButtonTextContainer,
    SecondaryNavigationItemContainer,
    SidebarButtonContainer,
    SidebarContentContainer,
    SidebarContentInnerContainer,
    SidebarLogoContainer,
    SolidButtonContainer,
} from './styles'
import SecondaryNavigationButtonItem from '@src/components/home-page-v3/header-section/secondary-navigation-button-item'
import { NavigationTransport } from '@common/transports/home-page-transport'
import HeaderSectionTransport from '@common/transports/layout/header-section-transport'
import NavigationItem from '@src/components/home-page-v3/header-section/navigation-item'
import { AnalyticsService } from '@src/services'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { homePageEventLabels } from '@src/services/analytics-service/labels'

interface Props {
    navigation: NavigationTransport
    headerSection: HeaderSectionTransport
    isLoggedIn: boolean
    isActionButtonVisible?: boolean
    isRegistrationFormVisible?: boolean
}

const HeaderSection: FC<Props> = ({
    headerSection: {
        id,
        logo: { image, url, eventLabel: logoEventLabel },
    },
    navigation: {
        loginButton,
        logoutButton,
        dashboardButton,
        actionButton,
        primaryNavigationButtons,
        secondaryNavigationButtons,
    },
    isLoggedIn,
    isActionButtonVisible,
    isRegistrationFormVisible = false,
}) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const _toggleSidebar = useCallback(() => setIsSidebarOpen(isSidebarOpen => !isSidebarOpen), [setIsSidebarOpen])

    const theme = useContext(ThemeContext)
    const { isMobile, isTablet } = useContext(ScreenContext)

    const _handleLogout = useCallback(() => {
        _toggleSidebar()
        // TODO: Handle clear device session cookie.
    }, [_toggleSidebar])

    const _handleLogoImageClick = useCallback(() => {
        if (logoEventLabel) {
            AnalyticsService.trackClickEvent(homePageEventCategories.HEADER_SECTION, logoEventLabel)
        }
    }, [logoEventLabel])

    const _handleLoginButtonClick = useCallback(() => {
        if (loginButton.eventLabel) {
            AnalyticsService.trackClickEvent(homePageEventCategories.HEADER_SECTION, loginButton.eventLabel)
        }
    }, [loginButton.eventLabel])

    const _handleRegisterButtonClick = useCallback(() => {
        if (actionButton.eventLabel) {
            AnalyticsService.trackClickEvent(homePageEventCategories.HEADER_SECTION, actionButton.eventLabel)
        }
    }, [actionButton.eventLabel])

    const _handleNavigationIconClick = useCallback(() => {
        _toggleSidebar()
        AnalyticsService.trackClickEvent(
            homePageEventCategories.HEADER_SECTION,
            homePageEventLabels.headerSection.NAVIGATION_MENU,
        )
    }, [_toggleSidebar])

    const _handleSideBarRegisterButtonClick = useCallback(() => {
        _toggleSidebar()
        _handleRegisterButtonClick()
    }, [_handleRegisterButtonClick, _toggleSidebar])

    const _handleSideBarLoginButtonClick = useCallback(() => {
        _toggleSidebar()
        _handleLoginButtonClick()
    }, [_handleLoginButtonClick, _toggleSidebar])

    const goBack = useCallback(() => {
        window.history.back()
    }, [])

    const GhostButtonWrapper = isRegistrationFormVisible ? GhostButtonContainerV2 : GhostButtonContainer

    return (
        <Header id={id}>
            <Container>
                <ContentContainer>
                    <LeftContainer>
                        {isMobile || isTablet ? (
                            <BackButton onClick={goBack}>
                                <BackIconContainer />
                            </BackButton>
                        ) : (
                            <ImageContainer href={url ?? undefined} onClick={_handleLogoImageClick}>
                                <Image src={image?.url} alt={image?.alt} />
                            </ImageContainer>
                        )}
                    </LeftContainer>
                    <RightContainer>
                        <NavigationContainer>
                            <PrimaryNavigationContainer>
                                {primaryNavigationButtons.map(nestedButtonObject => (
                                    <NavigationItem key={nestedButtonObject.url} button={nestedButtonObject} />
                                ))}
                            </PrimaryNavigationContainer>
                            {secondaryNavigationButtons.map(nestedButtonObject => (
                                <SecondaryNavigationItemContainer key={nestedButtonObject.text}>
                                    <SecondaryNavigationButtons
                                        title={nestedButtonObject.text}
                                        eventLabel={nestedButtonObject.eventLabel}
                                        buttons={nestedButtonObject.buttons}
                                    />
                                </SecondaryNavigationItemContainer>
                            ))}
                            <Line />
                        </NavigationContainer>
                        {/* {isMobile && (
                            <BackButton onClick={goBack}>
                                <BackIconContainer />
                            </BackButton>
                        )} */}
                        <GhostButtonWrapper>
                            {isLoggedIn ? (
                                <GhostButton
                                    onClick={_handleLogout}
                                    href={logoutButton.url}
                                    width={theme.layout.fill}
                                    color={theme.colors.tfwBlack87}
                                    padding="0"
                                >
                                    <HeaderButtonContentContainer>{logoutButton.text}</HeaderButtonContentContainer>
                                </GhostButton>
                            ) : (
                                <GhostButton
                                    onClick={_handleLoginButtonClick}
                                    href={loginButton.url}
                                    width={theme.layout.fill}
                                    color={theme.colors.tfwBlack87}
                                    padding="0"
                                >
                                    <HeaderButtonContentContainer>{loginButton.text}</HeaderButtonContentContainer>
                                </GhostButton>
                            )}
                        </GhostButtonWrapper>
                        {isActionButtonVisible && (
                            <SolidButtonContainer>
                                {isLoggedIn ? (
                                    <SolidButton href={dashboardButton.url} padding="0">
                                        <HeaderButtonContentContainer>
                                            {dashboardButton.text}
                                        </HeaderButtonContentContainer>
                                    </SolidButton>
                                ) : (
                                    <SolidButton
                                        href={actionButton.url}
                                        padding="0"
                                        onClick={_handleRegisterButtonClick}
                                    >
                                        <HeaderButtonContentContainer>{actionButton.text}</HeaderButtonContentContainer>
                                    </SolidButton>
                                )}
                            </SolidButtonContainer>
                        )}
                        <NavigationIconContainer>
                            <NavigationButton onClick={_handleNavigationIconClick} />
                        </NavigationIconContainer>
                    </RightContainer>
                </ContentContainer>
            </Container>
            <Sidebar
                isVisible={isSidebarOpen}
                top="0"
                handleOutsideClick={_toggleSidebar}
                maxWidth="22.5rem"
                zIndex="1002"
            >
                <SidebarContentContainer>
                    <SidebarContentInnerContainer>
                        <SidebarLogoContainer>
                            <ImageContainer href={url ?? undefined}>
                                <Image src={image?.url} alt={image?.alt} />
                            </ImageContainer>
                            <IconContainer onClick={_toggleSidebar}>
                                <CloseIcon />
                            </IconContainer>
                        </SidebarLogoContainer>
                        {secondaryNavigationButtons.map(nestedButtonObject => (
                            <SecondaryNavigationButtonContainer key={nestedButtonObject.text}>
                                <SecondaryNavigationButtonTextContainer>
                                    {nestedButtonObject.text}
                                </SecondaryNavigationButtonTextContainer>
                                {nestedButtonObject.buttons.map(button => (
                                    <SecondaryNavigationButtonItem
                                        key={button.url}
                                        onClick={_toggleSidebar}
                                        item={button}
                                        marginVertical={theme.spacing.medium}
                                    />
                                ))}
                            </SecondaryNavigationButtonContainer>
                        ))}
                    </SidebarContentInnerContainer>
                    <LowerContentContainer>
                        {isLoggedIn ? (
                            <Fragment>
                                <SidebarButtonContainer>
                                    <SolidButton
                                        onClick={_toggleSidebar}
                                        href={dashboardButton.url}
                                        width={theme.layout.fill}
                                        padding="0"
                                    >
                                        <ButtonContentContainer>{dashboardButton.text}</ButtonContentContainer>
                                    </SolidButton>
                                </SidebarButtonContainer>
                                <SidebarButtonContainer>
                                    <GhostButton
                                        onClick={_handleLogout}
                                        href={loginButton.url}
                                        width={theme.layout.fill}
                                        color={theme.colors.tfwBlack87}
                                        padding="0"
                                    >
                                        <ButtonContentContainer>{logoutButton.text}</ButtonContentContainer>
                                    </GhostButton>
                                </SidebarButtonContainer>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <SidebarButtonContainer>
                                    <SolidButton
                                        onClick={_handleSideBarRegisterButtonClick}
                                        href={actionButton.url}
                                        width={theme.layout.fill}
                                        padding="0"
                                    >
                                        <ButtonContentContainer>{actionButton.text}</ButtonContentContainer>
                                    </SolidButton>
                                </SidebarButtonContainer>
                                <SidebarButtonContainer>
                                    <GhostButton
                                        onClick={_handleSideBarLoginButtonClick}
                                        href={loginButton.url}
                                        width={theme.layout.fill}
                                        color={theme.colors.tfwBlack87}
                                        padding="0"
                                    >
                                        <ButtonContentContainer>{loginButton.text}</ButtonContentContainer>
                                    </GhostButton>
                                </SidebarButtonContainer>
                            </Fragment>
                        )}
                    </LowerContentContainer>
                </SidebarContentContainer>
            </Sidebar>
        </Header>
    )
}

export default memo(HeaderSection)
